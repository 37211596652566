import React, { useContext, useEffect, useRef, useState } from "react";
import { ContextApp } from "../utils/Context";
import iconBot from "../images/botIcon.svg";
import iconUser from "../images/userIcon.svg";
import Modal from "react-modal";
import { Spin } from 'antd';
import { toast } from "react-toastify";
import { PaperAirplaneIcon, CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import {
  downloadFile
} from "../services/uploadFilesApi";
function Chat() {
  const {
    message,
    showSlide,
    currentTopic,
    loadChatHistoryForTopic,
    isAwaitingNewResponse,
  } = useContext(ContextApp);

  const endOfMessagesRef = useRef(null);
  const [modalUrlIsOpen, setModalUrlIsOpen] = useState(false);
  const [fileDownloadURL, setFileDownloadURL] = useState(""); 
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const [hasLoadedHistory, setHasLoadedHistory] = useState(false);
  const messagesContainerRef = useRef(null);
  const [shouldLoadHistory, setShouldLoadHistory] = useState(false);
  const [showMetadata, setShowMetadata] = useState(false);

  const chatClass = `w-full h-[85%] overflow-hidden overflow-y-scroll ${
    showSlide ? "pl-12" : "pl-2"
  } pr-2`;

  const handleUserScroll = () => {
    if (messagesContainerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } =
        messagesContainerRef.current;
      if (scrollHeight - scrollTop > clientHeight * 1.5) {
        setShouldScrollToBottom(false);
      } else {
        setShouldScrollToBottom(true);
      }
    }
  };

  useEffect(() => {
    if (shouldScrollToBottom) {
      endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [message, shouldScrollToBottom]);

  useEffect(() => {
    if (currentTopic && shouldLoadHistory && !hasLoadedHistory) {
      loadChatHistoryForTopic(currentTopic);
      setHasLoadedHistory(true);
      setShouldLoadHistory(false);
    }
  }, [currentTopic, shouldLoadHistory, loadChatHistoryForTopic]);

  const toggleMetadata = () => {
    setShowMetadata((prev) => !prev);
  };

  const handleDownloadFile = async (fileHash, collectionName) => {
      toast.dismiss();
      const toastId = toast.warning("Generando URL para descargar archivo...", {
        autoClose: false,
      });
  
      try {
        // Llamar a la API
        const response = await downloadFile(fileHash, collectionName);
        console.log(response)
        // Asegurarse de que la URL viene en la respuesta
        const url = response;
        
        if (!url) {
          throw new Error("URL no encontrada");
        }
        // Guardar la URL y mostrar el modal
        setFileDownloadURL(url);
        toast.dismiss(toastId);
        toast.success('URL Generada correctamente')
        console.log(fileDownloadURL)
        setModalUrlIsOpen(true);
      } catch (error) {
        console.error("Error al generar la URL:", error);
        toast.dismiss(toastId);
        toast.error(error);
      }
    };

  return (
    <div
      ref={messagesContainerRef}
      id="chat-messages"
      className={chatClass}      
      onScroll={handleUserScroll}
    >
      {message && message.length > 0 ? (
        <div className="flex flex-col w-full justify-end pb-4">
          {message.map((msg, i) => (
            <div
              key={i}
              className={`w-full flex items-start mb-4 ${
                msg.isBot ? "bg-[#eeeeee] dark:bg-[#272828] p-2" : "bg-[#F3F6FB] dark:bg-[#191A1A]"
              }`}
            >
              <div
                className="flex w-full justify-center my-4 mx-auto"
                style={{ maxWidth: "45rem" }}
              >
                <div className="flex items-start w-full">
                  <img
                    src={msg.isBot ? iconBot : iconUser}
                    alt={msg.isBot ? "Bot" : "User"}
                    className="w-8 h-8 object-cover dark:invert dark:brightness-0 mr-2 mt-1"
                  />
                  <div className="flex-1">
                    {/* Renderizar texto principal */}
                    <p
                      className="whitespace-pre-wrap text-justify dark:text-white"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {typeof msg.text === "string" && msg.isBot && msg.text.startsWith(" ")
                        ? msg.text.slice(1)
                        : msg.text}
                    </p>
                    {/* Botón para mostrar/ocultar metadata */}
                   {/* 🔹 Mostrar metadata SOLO si NO es FAQ_ANSWER y la metadata existe */}
  {msg.metadata && msg.metadata.length > 0 && !msg.FAQ_ANSWER && (
    <>
      <button
        onClick={toggleMetadata}
        className="text-blue-600 underline dark:text-blue-400"
      >
        <br />
        {showMetadata ? "Ocultar Metadatos" : "Mostrar Metadatos"}
      </button>

      {showMetadata && (
        <div className="metadata-container mt-4 p-4 bg-gray-100 dark:bg-[#191A1A] rounded shadow">
          <h3 className="font-bold text-lg mb-4 dark:text-white">Documentos relacionados:</h3>
          {msg.metadata.map((doc, index) => (
            <div key={index} className="metadata-item mb-4 p-3 border-b last:border-none dark:border-gray-600">
              <p className="font-semibold text-blue-500 dark:text-blue-300">
                Documento: {doc.document_name}
              </p>
              <p className="text-gray-700 dark:text-gray-400">Página: {doc.page_number}</p>
            </div>
          ))}
        </div>
      )}
    </>
  )}
                    {/* Renderizar metadata si está visible */}
                    {showMetadata && msg.metadata && Array.isArray(msg.metadata) && msg.metadata.length > 0 && (
                      <div className="metadata-container mt-4 p-4 bg-gray-100 dark:bg-[#191A1A] rounded shadow">
                        <h3 className="font-bold text-lg mb-4 dark:text-white">Documentos relacionados:</h3>
                        {msg.metadata.map((doc, index) => (
                          <div
                            key={index}
                            className="metadata-item mb-4 p-3 border-b last:border-none dark:border-gray-600"
                          >
                            <p className="font-semibold text-blue-500 dark:text-blue-300">
                              Documento: {doc.document_name}
                            </p>
                            <p className="text-gray-700 dark:text-gray-400">Página: {doc.page_number}</p>
                            {/* <a
  href="#"
  onClick={(e) => {
    e.preventDefault(); // Evitar el comportamiento predeterminado del hipervínculo
    handleDownloadFile(doc.document_hash, localStorage.getItem("selectedCollection"));
  }}
  className="text-blue-500 hover:text-blue-700"
>
  Descargar Archivo
</a> */}

                          </div>
                        ))}
                        
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            
          ))}
          {isAwaitingNewResponse && (
            <div className="flex justify-center items-center h-full">
              <Spin />
            </div>
          )}
          <Modal
            isOpen={modalUrlIsOpen}
            onRequestClose={() => setModalUrlIsOpen(false)}
            contentLabel="URL de Descarga"
            ariaHideApp={false}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.7)", // Fondo opaco oscuro
              },
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                padding: "0", // Controlado por Tailwind
                border: "none", // Evita bordes predeterminados
                background: "none", // Estilo desde el contenedor
              },
            }}
          >
            <div className="bg-white dark:bg-[#191919] rounded-lg p-6 shadow-lg max-w-md mx-auto">
              <h2 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
                URL de Descarga
              </h2>
              <p className="mb-4 break-words text-gray-700 dark:text-gray-300">
                <a
                  href={fileDownloadURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  className="inline-block bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200"
                >
                  Descargar Archivo
                </a>
              </p>
              <div className="flex justify-end">
                <button
                  onClick={() => setModalUrlIsOpen(false)}
                  className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-200"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </Modal>
          
          {/* ref al final de los mensajes para el auto-scroll */}
          <div ref={endOfMessagesRef} />
        </div>
      ) : (
        <div className=" bg-[#F3F6FB] dark:bg-[#191A1A] flex flex-col items-center justify-center h-full">
          <div className="text-center space-y-4">
            <img src="./logo_grupoefe.png" alt="Logo" className="mx-auto mb-4 w-64 mb-8 dark:filter dark:invert dark:brightness-0" />
            <div className="flex flex-wrap justify-center gap-4">
              <div className="bg-gray-100 dark:bg-[#1f2121] p-4 dark:text-white rounded-lg shadow-md flex-1 min-w-[250px] max-w-[300px] flex flex-col items-center">
                <PaperAirplaneIcon className="w-10 h-10 mb-2 text-gray-600" />
                <h3 className="text-lg font-semibold">Capacidad 1</h3>
                <p className="text-gray-600 dark:text-white">Cesarin puede responder sobre los documentos cargados en cada colección.</p>
              </div>
              <div className="bg-gray-100 dark:bg-[#1f2121] p-4 rounded-lg shadow-md flex-1 min-w-[250px] max-w-[300px] flex flex-col items-center">
                <CheckCircleIcon className="w-10 h-10 mb-2 text-gray-600" />
                <h3 className="text-lg font-semibold">Capacidad 2</h3>
                <p className="text-gray-600 dark:text-white">Cesarin aprende continuamente del contexto de la conversación.</p>
              </div>
              
              <div className="bg-gray-100 dark:bg-[#1f2121] p-4 rounded-lg shadow-md flex-1 min-w-[250px] max-w-[300px] flex flex-col items-center">
                <ExclamationCircleIcon className="w-10 h-10 mb-2 text-gray-600" />
                <h3 className="text-lg font-semibold">Sugerencias</h3>
                <p className="text-gray-600 dark:text-white">Realice las preguntas a Cesarin de la forma más específica posible.</p>
              </div>
            </div>
          </div>
        </div>
        
      )}
      
    </div>
    
  );
}

export default Chat;
