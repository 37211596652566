import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";

function FAQsModal({ onClose }) {
    
  const [faqs, setFaqs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFaq, setSelectedFaq] = useState(null); // Para editar la respuesta o preguntas asociadas
  const [editedResponse, setEditedResponse] = useState("");
  const [mainQuery, setMainQuery] = useState(""); // Para la pregunta principal en edición
  const [newMainQuery, setNewMainQuery] = useState(""); // Para la pregunta principal en creación
  const [newRanking, setNewRanking] = useState(1);

  const [editedQueries, setEditedQueries] = useState([]); // Para editar las preguntas asociadas
  const [isAdding, setIsAdding] = useState(false); // Estado para el modal de agregar
  const [newResponse, setNewResponse] = useState("");
  const [newQueries, setNewQueries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Función para obtener las preguntas frecuentes de la API
  const fetchFAQs = async () => {
    try {
      const response = await fetch("https://ef6epzyw3f.execute-api.us-east-1.amazonaws.com/faqs", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Error al obtener las preguntas frecuentes");
      }

      const result = await response.json();
      setFaqs(result.data || []);
    } catch (error) {
      toast.error("Error al cargar las preguntas frecuentes");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFAQs();
  }, []);

  const handleBackdropClick = (event) => {
    if (event.target.classList.contains("modal-backdrop")) {
      onClose();
    }
  };

  const openEditModal = (faq) => {
    setSelectedFaq(faq);
    setMainQuery(faq.main_query)
    setEditedResponse(faq.response); // Cargar la respuesta actual
    setEditedQueries(faq.queries || []); // Cargar las preguntas asociadas actuales
  };

  const handleSaveResponseAndQueries = async () => {
    if (!selectedFaq) return;

    try {
      const response = await fetch(
        "https://ef6epzyw3f.execute-api.us-east-1.amazonaws.com/faqs",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            hash_key: selectedFaq.hash_key,
            response: editedResponse,
            main_query: mainQuery, // Nuevo campo
            queries: editedQueries,
            isFrequent: selectedFaq.isFrequent || false, // Asegura que siempre se envíe un valor
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Error desconocido en el backend");
      }

      toast.success("Respuesta y preguntas asociadas actualizadas correctamente");

      setIsLoading(true);
      await fetchFAQs(); // Recargar los datos desde la API
      setIsLoading(false);
      setSelectedFaq(null);
    } catch (error) {
      toast.error(`Error al guardar la respuesta y las preguntas: ${error.message}`);
      console.error(error);
    }
  };

  const handleDeleteFAQ = async (hashKey) => {
    try {
      const response = await fetch(
        "https://ef6epzyw3f.execute-api.us-east-1.amazonaws.com/faqs",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            hash_key: hashKey,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Error desconocido en el backend");
      }

      toast.success("Pregunta frecuente eliminada correctamente");

      setIsLoading(true);
      await fetchFAQs(); // Recargar las preguntas frecuentes desde la API
      setIsLoading(false);
    } catch (error) {
      toast.error(`Error al eliminar la pregunta frecuente: ${error.message}`);
      console.error(error);
    }
  };

  const handleAddFAQ = async () => {
    if (!newResponse || newQueries.length === 0) {
      toast.error("Por favor, completa todos los campos");
      return;
    }

    try {
      const response = await fetch(
        "https://ef6epzyw3f.execute-api.us-east-1.amazonaws.com/faqs",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            response: newResponse,            
            main_query: newMainQuery, // Nuevo campo
            queries: newQueries,
            isFrequent: true,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Error desconocido en el backend");
      }

      toast.success("Pregunta frecuente agregada correctamente");

      setIsLoading(true);
      await fetchFAQs(); // Recargar los datos desde la API
      setIsLoading(false);
      setIsAdding(false);
      setNewResponse("");
      setNewRanking(1)
      setNewMainQuery("");
      setNewQueries([]);
    } catch (error) {
      toast.error(`Error al agregar la pregunta frecuente: ${error.message}`);
      console.error(error);
    }
  };

  const handleQueryChange = (index, value) => {
    setEditedQueries((prevQueries) =>
      prevQueries.map((query, i) => (i === index ? value : query))
    );
  };

  const addQuery = () => {
    setEditedQueries((prevQueries) => [...prevQueries, ""]);
  };

  const removeQuery = (index) => {
    setEditedQueries((prevQueries) => prevQueries.filter((_, i) => i !== index));
  };

  const addNewQuery = () => {
    setNewQueries((prevQueries) => [...prevQueries, ""]);
  };

  const removeNewQuery = (index) => {
    setNewQueries((prevQueries) => prevQueries.filter((_, i) => i !== index));
  };

  // Paginación
  const totalPages = Math.ceil(faqs.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedFaqs = faqs.slice(startIndex, startIndex + itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return ReactDOM.createPortal(
    <div
      className="modal-backdrop fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      onClick={handleBackdropClick}
    >
      <div className="bg-gray-100 dark:bg-[#1c1d1d] p-8 rounded-lg w-full max-w-6xl mx-4 h-auto max-h-[90vh] overflow-y-auto flex flex-col shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-center dark:text-white">Respuestas Frecuentes</h2>
          <button
            onClick={() => setIsAdding(true)}
            className="py-2 px-4 bg-green-500 text-white rounded hover:bg-green-600 transition"
          >
            Agregar
          </button>
        </div>
        
        {/* Tabla de preguntas frecuentes */}
        {isLoading ? (
          <div className="text-center py-4">
            <p className="text-lg font-medium text-gray-700 dark:text-gray-200">Cargando...</p>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full border-collapse border border-gray-300 dark:border-gray-700">
              <thead className="bg-gray-200 dark:bg-gray-800">
                <tr>
                  <th className="border border-gray-300 dark:border-gray-700 px-4 py-2 text-left">Id</th>
                  <th className="border border-gray-300 dark:border-gray-700 px-4 py-2 text-left">Rank</th>
                  <th className="border border-gray-300 dark:border-gray-700 px-4 py-2 text-left">Respuesta</th>
                  <th className="border border-gray-300 dark:border-gray-700 px-4 py-2 text-left">Pregunta Principal</th>
                  <th className="border border-gray-300 dark:border-gray-700 px-4 py-2 text-left">Preguntas Asociadas</th>
                  <th className="border border-gray-300 dark:border-gray-700 px-4 py-2 text-left">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {paginatedFaqs.map((faq,i) => (
                  <tr key={faq.hash_key} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                    <td className="border border-gray-300 dark:border-gray-700 px-4 py-2 flex items-center space-x-2">
                      <span className="truncate">{faq.hash_key.slice(0, 8)}...</span>
                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(faq.hash_key);
                          toast.success("Hash key copiado al portapapeles");
                        }}
                        className="p-1 text-blue-500 hover:text-blue-700 transition"
                        aria-label="Copiar Hash Key"
                      >
                        📋
                      </button>
                    
                    </td>
                    <td className="justify-center text-center border border-gray-300 dark:border-gray-700 px-4 py-2">{i}</td>
                    
                    <td className="border border-gray-300 dark:border-gray-700 px-4 py-2">{faq.response}</td>
                    
                    <td className="border border-gray-300 dark:border-gray-700 px-4 py-2">{faq.main_query}</td>
                    <td className="border border-gray-300 dark:border-gray-700 px-4 py-2">
                      {faq.queries && faq.queries.length > 0
                        ? faq.queries.join(", ")
                        : "Sin preguntas asociadas"}
                    </td>
                    <td className="border border-gray-300 dark:border-gray-700 px-4 py-2 flex space-x-2">
                      <button
                        onClick={() => openEditModal(faq)}
                        className="py-1 px-3 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition"
                      >
                        Editar
                      </button>
                      <button
                        onClick={() => handleDeleteFAQ(faq.hash_key)}
                        className="py-1 px-3 bg-red-500 text-white rounded hover:bg-red-600 transition"
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Paginación */}
            <div className="flex justify-between items-center mt-4">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className={`py-2 px-4 rounded-lg ${
                  currentPage === 1 ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-700"
                } text-white transition`}
              >
                Anterior
              </button>
              <p className="text-gray-700 dark:text-gray-200">
                Página {currentPage} de {totalPages}
              </p>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className={`py-2 px-4 rounded-lg ${
                  currentPage === totalPages ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-700"
                } text-white transition`}
              >
                Siguiente
              </button>
            </div>
          </div>
        )}

       {/* Modal de Edición */}
{selectedFaq && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
    <div className="relative bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
      
      {/* Botón de cierre (X) */}
      <button
        onClick={() => setSelectedFaq(null)} // Cierra el modal al hacer clic
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        aria-label="Cerrar"
      >
        ✕
      </button>

      <h3 className="text-lg font-bold text-gray-800 dark:text-white mb-4">Editar Respuesta</h3>
      
      <textarea
        value={editedResponse}
        onChange={(e) => setEditedResponse(e.target.value)}
        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600 mb-4"
        rows={5}
      ></textarea>

      {/* Pregunta Principal */}
      <h4 className="text-md font-bold text-gray-800 dark:text-white mb-2">Pregunta Principal</h4>
      <input
        type="text"
        value={mainQuery}
        onChange={(e) => setMainQuery(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600 mb-4"
        placeholder="Escribe la pregunta principal aquí..."
      />

      {/* Preguntas Secundarias */}
      <h4 className="text-md font-bold text-gray-800 dark:text-white mb-2">Preguntas Secundarias</h4>
      {editedQueries.map((query, index) => (
        <div key={index} className="flex items-center mb-2">
          <input
            type="text"
            value={query}
            onChange={(e) => handleQueryChange(index, e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
            placeholder={`Pregunta secundaria ${index + 1}`}
          />
          <button
            onClick={() => removeQuery(index)}
            className="ml-2 px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition"
          >
            Eliminar
          </button>
        </div>
      ))}
      <button
        onClick={addQuery}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
      >
        Agregar Pregunta
      </button>

      <div className="flex justify-end mt-4">
        <button
          onClick={() => setSelectedFaq(null)}
          className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-lg text-gray-800 mr-2"
        >
          Cancelar
        </button>
        <button
          onClick={handleSaveResponseAndQueries}
          className="px-4 py-2 bg-blue-500 hover:bg-blue-600 rounded-lg text-white"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
)}


        {/* Modal de Agregar */}
{/* Modal de Agregar */}
{isAdding && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
    <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
      <h3 className="text-lg font-bold text-gray-800 dark:text-white mb-4">Agregar Nueva Respuesta Frecuente</h3>
      
      {/* Respuesta */}
      <textarea
        value={newResponse}
        onChange={(e) => setNewResponse(e.target.value)}
        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600 mb-4"
        rows={5}
        placeholder="Escribe la respuesta aquí..."
      ></textarea>

      {/* Pregunta Principal */}
      <h4 className="text-md font-bold text-gray-800 dark:text-white mb-2">Pregunta Principal</h4>
      <input
        type="text"
        value={newMainQuery}
        onChange={(e) => setNewMainQuery(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600 mb-4"
        placeholder="Escribe la pregunta principal aquí..."
      />

      {/* Ranking */}
      <h4 className="text-md font-bold text-gray-800 dark:text-white mb-2">Ranking</h4>
      <input
        type="number"
        value={newRanking}
        onChange={(e) => setNewRanking(Number(e.target.value))}
        className="w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600 mb-4"
        placeholder="Ingrese el ranking (ej: 1, 2, 3...)"
      />

      {/* Preguntas Secundarias */}
      <h4 className="text-md font-bold text-gray-800 dark:text-white mb-2">Preguntas Secundarias</h4>
      {newQueries.map((query, index) => (
        <div key={index} className="flex items-center mb-2">
          <input
            type="text"
            value={query}
            onChange={(e) =>
              setNewQueries((prevQueries) =>
                prevQueries.map((q, i) => (i === index ? e.target.value : q))
              )
            }
            className="w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
            placeholder={`Pregunta secundaria ${index + 1}`}
          />
          <button
            onClick={() => removeNewQuery(index)}
            className="ml-2 px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition"
          >
            Eliminar
          </button>
        </div>
      ))}
      <button
        onClick={addNewQuery}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
      >
        Agregar Pregunta Secundaria
      </button>

      {/* Botones de Acción */}
      <div className="flex justify-end mt-4">
        <button
          onClick={() => setIsAdding(false)}
          className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-lg text-gray-800 mr-2"
        >
          Cancelar
        </button>
        <button
          onClick={handleAddFAQ}
          className="px-4 py-2 bg-green-500 hover:bg-green-600 rounded-lg text-white"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
)}


      </div>
    </div>,
    document.getElementById("modal-root")
  );
}

export default FAQsModal;

