import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Page/Home";
import Login from "./Page/Login";
import GuestChatRedirect from "./components/GuestChatRedirect";
import ProtectedRoute from "./hooks/ProtectedRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UsersnapProvider } from "./UsersnapContext";
import CustomButton from "./components/CustomButton";
import Changelog from "./components/shared/Changelog";

function App() {
  const [currentExample, setCurrentExample] = useState("basic");
  
  return (
    <BrowserRouter>
      <ToastContainer />
      <div className="overflow-hidden">
        <Routes>
          {/* Ruta de Login (opcional si se usa login externo) */}
          <Route path="/login" element={<Login />} />
          <Route path="/guest-chat" element={<GuestChatRedirect />} />
          <Route path="/changelog" element={<Changelog />} />
          {/* Ruta protegida para la home */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Home />
                <UsersnapProvider>
                  <CustomButton />
                </UsersnapProvider>
                <div className="examples"></div>
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
