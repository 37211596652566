import React, { useState } from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    RiUpload2Line,
    RiDeleteBin6Line,
    RiFileAddLine,
    RiFolder3Line,
    RiArrowLeftLine,
    RiAddLine,
    RiDeleteBinLine,
    RiDownload2Fill,
  } from "react-icons/ri";
import {
    getUserDocuments,
    uploadMultipleFiles,
    deleteFile,
    downloadFile,
  } from "../../services/uploadFilesApi";
import { AiOutlineDownload, AiOutlineSearch, AiOutlineClear, AiOutlineFilter } from 'react-icons/ai'; // Agregar iconos

const DocumentAnalysisResults = ({ results }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [modalUrlIsOpen, setModalUrlIsOpen] = useState(false);
        const [fileDownloadURL, setFileDownloadURL] = useState(""); 
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [textPercentageFilter, setTextPercentageFilter] = useState(100);
    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
    const resultsPerPage = 4;

    if (!results) {
        return (
            <p className="text-red-500 mt-4">
                Debes hacer clic en "Obtener estadísticas" para tener los resultados.
            </p>
        );
    }

    const { archivos_con_error, ...stats } = results;
    const statsArray = Object.entries(stats);

    // Filtrar resultados según búsqueda y porcentaje
    const filteredResults = statsArray.filter(([fileName]) => {
        const includesSearchQuery = decodeURIComponent(fileName).toLowerCase().includes(searchQuery.toLowerCase());
        const meetsPercentageCriteria = stats[fileName].porcentaje_promedio_texto <= textPercentageFilter;
        return includesSearchQuery && meetsPercentageCriteria;
    });

    const totalFilesAnalyzed = filteredResults.length; // Contador de archivos
    const totalPages = Math.ceil(filteredResults.length / resultsPerPage);
    const currentStats = filteredResults.slice((currentPage - 1) * resultsPerPage, currentPage * resultsPerPage);

     // Cálculo de indicadores globales de calidad de documentos
     const totalTextPercentage = statsArray.reduce((acc, [_, stat]) => acc + stat.porcentaje_promedio_texto, 0);
     const averageTextPercentage = (totalTextPercentage / statsArray.length).toFixed(2);
 
     const totalImagePercentage = statsArray.reduce((acc, [_, stat]) => acc + stat.porcentaje_promedio_imagenes, 0);
     const averageImagePercentage = (totalImagePercentage / statsArray.length).toFixed(2);
 
     const totalCharsPerPage = statsArray.reduce((acc, [_, stat]) => acc + stat.promedio_caracteres_por_pagina, 0);
     const averageCharsPerPage = (totalCharsPerPage / statsArray.length).toFixed(2);
 
     const totalImagesPerPage = statsArray.reduce((acc, [_, stat]) => acc + stat.promedio_imagenes_por_pagina, 0);
     const averageImagesPerPage = (totalImagesPerPage / statsArray.length).toFixed(2);
 
     const lowTextDocuments = statsArray.filter(([_, stat]) => stat.porcentaje_promedio_texto < 25).length;
     const lowTextPercentage = ((lowTextDocuments / statsArray.length) * 100).toFixed(2);
 
     const totalErrors = archivos_con_error ? archivos_con_error.length : 0;
     const errorPercentage = ((totalErrors / statsArray.length) * 100).toFixed(2);
 
   const handleDownloadFile = async (fileHash, collectionName) => {
      toast.dismiss();
      const toastId = toast.warning("Generando URL para descargar archivo...", {
        autoClose: false,
      });
  
      try {
        // Llamar a la API
        const response = await downloadFile(fileHash, collectionName);
        console.log(response)
        // Asegurarse de que la URL viene en la respuesta
        const url = response;
        
        if (!url) {
          throw new Error("URL no encontrada");
        }
        // Guardar la URL y mostrar el modal
        setFileDownloadURL(url);
        toast.dismiss(toastId);
        toast.success('URL Generada correctamente')
        console.log(fileDownloadURL)
        setModalUrlIsOpen(true);
      } catch (error) {
        console.error("Error al generar la URL:", error);
        toast.dismiss(toastId);
        toast.error(error);
      }
    };

    const downloadAnalysis = () => {
        const csvContent = "data:text/csv;charset=utf-8," +
            "Nombre de Archivo,Total Caracteres,Total Páginas,Total Imágenes,Promedio Texto,Promedio Imágenes,Promedio Caracteres por Página,Promedio Imágenes por Página\n" +
            filteredResults.map(([fileName, stats]) => {
                const percentageAverageText = stats.porcentaje_promedio_texto.toFixed(2);
                return [
                    decodeURIComponent(fileName),
                    stats.total_characters,
                    stats.total_pages,
                    stats.total_images,
                    percentageAverageText,
                    stats.porcentaje_promedio_imagenes.toFixed(2),
                    stats.promedio_caracteres_por_pagina.toFixed(2),
                    stats.promedio_imagenes_por_pagina.toFixed(2)
                ].join(',');
            }).join('\n');

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'document_analysis_results.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const clearFilters = () => {
        setSearchQuery('');
        setTextPercentageFilter(100);
        setCurrentPage(1);
    };

    return (
        <div className="mt-4">
            

            
{/* Indicadores globales de calidad */}
<div className="mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
    <div className="bg-white border border-gray-300 rounded-lg shadow-md p-4">
        <h2 className="text-xl font-bold text-blue-600">Porcentaje promedio de texto</h2>
        <p className="text-2xl font-semibold text-gray-800">{averageTextPercentage}%</p>
    </div>
    <div className="bg-white border border-gray-300 rounded-lg shadow-md p-4">
        <h2 className="text-xl font-bold text-blue-600">Porcentaje promedio de imágenes</h2>
        <p className="text-2xl font-semibold text-gray-800">{averageImagePercentage}%</p>
    </div>
    <div className="bg-white border border-gray-300 rounded-lg shadow-md p-4">
        <h2 className="text-xl font-bold text-blue-600">Promedio de caracteres por página</h2>
        <p className="text-2xl font-semibold text-gray-800">{averageCharsPerPage}</p>
    </div>
    <div className="bg-white border border-gray-300 rounded-lg shadow-md p-4">
        <h2 className="text-xl font-bold text-blue-600">Promedio de imágenes por página</h2>
        <p className="text-2xl font-semibold text-gray-800">{averageImagesPerPage}</p>
    </div>
    <div className="bg-white border border-gray-300 rounded-lg shadow-md p-4">
        <h2 className="text-xl font-bold text-blue-600">Documentos con menos del 25% de texto</h2>
        <p className="text-2xl font-semibold text-red-600">{lowTextPercentage}%</p>
    </div>
    <div className="bg-white border border-gray-300 rounded-lg shadow-md p-4">
        <h2 className="text-xl font-bold text-blue-600">Porcentaje de documentos con errores</h2>
        <p className="text-2xl font-semibold text-red-600">{errorPercentage}%</p>
    </div>
</div>

            {/* Campo de búsqueda y botones */}
            <div className="relative">
                <AiOutlineSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => {
                        setSearchQuery(e.target.value);
                        setCurrentPage(1);
                    }}
                    placeholder="Buscar por nombre de archivo"
                    className="pl-8 p-2 mb-2 border rounded w-96 shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
            </div>
            <div className="mt-4 mb-4 flex items-center gap-2">
                <button
                    onClick={downloadAnalysis}
                    className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-md shadow-lg hover:bg-blue-600 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
                >
                    <AiOutlineDownload /> Descargar Análisis .csv
                </button>

                <button
                    onClick={clearFilters}
                    className="flex items-center gap-2 px-4 py-2 bg-red-500 text-white rounded-md shadow-lg hover:bg-red-600 transition-colors duration-300"
                >
                    <AiOutlineClear /> Limpiar Filtros
                </button>
            </div>

            {/* Botón para mostrar/ocultar filtros avanzados */}
            <button
                onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
                className="mb-4 flex items-center gap-2 px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400 transition-colors duration-300"
            >
                <AiOutlineFilter />
                {showAdvancedFilters ? 'Ocultar Filtros Avanzados' : 'Mostrar Filtros Avanzados'}
            </button>
{/* Mostrar el contador de archivos analizados */}
<div className="mb-4">
                <label className="font-bold text-gray-600">Archivos analizados: {totalFilesAnalyzed}</label>
            </div>
            {/* Filtros avanzados */}
            {showAdvancedFilters && (
                <div className="mb-4">
                    <label className="block mb-2">
                        Filtrar por porcentaje de texto (mínimo: {textPercentageFilter}%)
                    </label>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={textPercentageFilter}
                        onChange={(e) => {
                            setTextPercentageFilter(Number(e.target.value));
                            setCurrentPage(1);
                        }}
                        className="w-full"
                    />
                </div>
            )}

            {/* Mensaje de carga */}
            {loading ? (
                <p className="text-blue-500">Cargando...</p>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {currentStats.length > 0 ? (
                        currentStats.map(([fileName, stats]) => {
                            const percentageAverageText = stats.porcentaje_promedio_texto.toFixed(2);
                            const backgroundColor = percentageAverageText < 25 ? 'bg-red-200' : 'bg-white';
                            const borderColor = percentageAverageText < 25 ? 'border-red-500' : 'border-gray-300';

                            return (
                                <div key={fileName} className={`border ${borderColor} ${backgroundColor} p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300`}>
                                    {/* Icono de PDF en la parte superior con texto */}
                                    {fileName.endsWith('.pdf') && (
                                        <div className="flex flex-col items-center mb-2">
                                            <svg className="w-12 h-12 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8l-6-6z" />
                                                <path d="M14 2v6h6" />
                                            </svg>
                                            <span className="text-red-600 text-xs mt-1">PDF</span>
                                        </div>
                                    )}
                                    {/* Nombre del archivo, truncado si es largo */}
                                    <h3 className="text-lg font-semibold truncate" title={decodeURIComponent(fileName)}>
                                        {decodeURIComponent(fileName)}
                                    </h3>
                                    
                                                  
                                    <div className="break-words">
                                        <button
                                                    onClick={() =>
                                                      handleDownloadFile(fileName.FILE_HASH, fileName.COLLECTION_NAME)
                                                    }
                                                    className="text-blue-500 hover:text-blue-700"
                                                  >
                                                    <RiDownload2Fill className="text-lg" />
                                                  </button>
                                        <p>Total Caracteres: {stats.total_characters}</p>
                                        <p>Total Páginas: {stats.total_pages}</p>
                                        <p>Total Imágenes: {stats.total_images}</p>
                                        <p>Promedio Texto: {percentageAverageText}%</p>
                                        <p>Promedio Imágenes: {stats.porcentaje_promedio_imagenes.toFixed(2)}%</p>
                                        <p>Promedio Caracteres por Página: {stats.promedio_caracteres_por_pagina.toFixed(2)}</p>
                                        <p>Promedio Imágenes por Página: {stats.promedio_imagenes_por_pagina.toFixed(2)}</p>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <p className="text-red-500">No se encontraron resultados.</p>
                    )}
                </div>
            )}

            {/* Paginación */}
            {filteredResults.length > 0 && (
                <div className="mt-4 flex justify-between items-center">
                    <button
                        className="p-2 bg-gray-200 rounded shadow hover:bg-gray-300 transition-colors duration-300"
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        Anterior
                    </button>
                    <p>Página {currentPage} de {totalPages}</p>
                    <button
                        className="p-2 bg-gray-200 rounded shadow hover:bg-gray-300 transition-colors duration-300"
                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    >
                        Siguiente
                    </button>
                </div>
            )}
            {/* Mostrar archivos con error, si los hay */}
            {archivos_con_error && archivos_con_error.length > 0 && (
                <div className="mt-4">
                    <h3 className="text-lg font-semibold">Archivos con Error:</h3>
                    <ul>
                        {archivos_con_error.map((error) => (
                            <li key={error.archivo} className="text-red-500">
                                {decodeURIComponent(error.archivo)}: {error.error}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
        
    );
};

export default DocumentAnalysisResults;
