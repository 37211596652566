import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
//import LogoImg from "../../images/LogoLogin.png";
import { changePassword, login } from "../../services/loginApi";
import { toast } from "react-toastify";
import { ContextApp } from "../../utils/Context";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

function Login() {
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [needPasswordChange, setNeedPasswordChange] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { setIsLoggedIn, setIsGuest } = useContext(ContextApp);
  const [session, setSession] = useState("");
  const [adLoginUrl, setAdLoginUrl] = useState("");
  const [newPasswordCriteria, setNewPasswordCriteria] = useState({});
  const [confirmNewPasswordCriteria, setConfirmNewPasswordCriteria] = useState(
    {}
  );
  const [showNewPasswordCriteria, setShowNewPasswordCriteria] = useState(false);
  const [showConfirmPasswordCriteria, setShowConfirmPasswordCriteria] =
    useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const newPasswordInputRef = useRef(null);
  const confirmNewPasswordInputRef = useRef(null);
  const toggleButtonRef = useRef(null);
  const confirmToggleButtonRef = useRef(null);
  const [textConfig, setTextConfig] = useState({});

  const validatePassword = (password) => {
    const criteria = {
      length: password.length >= 8,
      number: /\d/.test(password),
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      specialChar:
        /[!@#$%^&*(),.?":{}|<>_ ]/.test(password) ||
        (/[^ ]/.test(password) && password.trim() !== password),
    };
    return criteria;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleGuestLogin = () => {
    setIsLoggedIn(false);
    setIsGuest(true);
    navigate("/guest-chat");
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  const handleNewPasswordFocus = () => {
    setShowNewPasswordCriteria(true);
  };

  const handleConfirmPasswordFocus = () => {
    setShowConfirmPasswordCriteria(true);
  };

  const handleNewPasswordBlur = (event) => {
    if (
      toggleButtonRef.current &&
      toggleButtonRef.current.contains(event.relatedTarget)
    ) {
      return;
    }
    setShowNewPasswordCriteria(false);
  };

  const handleConfirmPasswordBlur = (event) => {
    if (
      confirmToggleButtonRef.current &&
      confirmToggleButtonRef.current.contains(event.relatedTarget)
    ) {
      return;
    }
    setShowConfirmPasswordCriteria(false);
  };

  const handleNewPasswordSubmit = async (e) => {
    e.preventDefault();
    toast.dismiss();

    if (newPassword !== confirmNewPassword) {
      setErrorMessage(textConfig.LOGIN_TOAST_PASSWORD_MISMATCH);
      toast.error(textConfig.LOGIN_TOAST_PASSWORD_MISMATCH, {
        autoClose: 5000,
      });
      return;
    }

    if (Object.values(newPasswordCriteria).includes(false)) {
      setErrorMessage(textConfig.LOGIN_TOAST_PASSWORD_CRITERIA_FAIL);
      toast.error(textConfig.LOGIN_TOAST_PASSWORD_CRITERIA_FAIL, {
        autoClose: 5000,
      });
      return;
    }

    toast.info(textConfig.LOGIN_CHANGING_PASSWORD);
    try {
      setIsSubmitting(true);
      await changePassword(user, newPassword, session);
      toast.dismiss();
      toast.success(textConfig.LOGIN_TOAST_PASSWORD_CHANGE_SUCCESS);
      setIsLoggedIn(true);
      setIsGuest(false);
      navigate("/");
    } catch (error) {
      console.error("Error al cambiar la contraseña:", error);
      toast.dismiss();
      setErrorMessage(textConfig.LOGIN_FAILED_CHANGE_PASSWORD);
      toast.error(textConfig.LOGIN_TOAST_PASSWORD_CHANGE_FAIL, {
        autoClose: 5000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.dismiss();
    toast.info(textConfig.LOGIN_TRYING_LOGIN);
    try {
      const response = await login(user, password);
      toast.dismiss();
      if (response.ChallengeName === "NEW_PASSWORD_REQUIRED") {
        setNeedPasswordChange(true);
        setSession(response.Session);
        toast.success(textConfig.LOGIN_NEED_CHANGE_PASSWORD);
        setErrorMessage(textConfig.LOGIN_NEED_CHANGE_PASSWORD);
      } else {
        toast.success(textConfig.LOGIN_TOAST_LOGIN_SUCCESS);
        setIsLoggedIn(true);
        setIsGuest(false);
        navigate("/");
      }
    } catch (error) {
      console.error(error);
      toast.dismiss();
      if (
        error.message ===
        "Error del servidor. Por favor, inténtelo de nuevo más tarde."
      ) {
        toast.error(error.message, { autoClose: 5000 });
      } else {
        setErrorMessage(textConfig.LOGIN_TOAST_LOGIN_FAIL);
        toast.error(textConfig.LOGIN_TOAST_LOGIN_FAIL, { autoClose: 5000 });
      }
    }
  };

  const fetchTextConfig = async () => {
    try {
      const response = await fetch("/text.json");
      const data = await response.json();
      setTextConfig(data);
    } catch (error) {
      console.error("Error loading text config:", error);
    }
  };

  const fetchAdLoginUrl = async () => {
    try {
      const response = await fetch("/api.json");
      const data = await response.json();
      setAdLoginUrl(data.API_ACTIVE_DIRECTORY);
    } catch (error) {
      console.error("Error loading API config:", error);
    }
  };

  useEffect(() => {
    fetchTextConfig();
    fetchAdLoginUrl();
  }, []);

  useEffect(() => {
    const newCriteria = validatePassword(newPassword);
    const confirmCriteria = validatePassword(confirmNewPassword);
    setIsPasswordValid(Object.values(newCriteria).every(Boolean));
    setIsConfirmPasswordValid(Object.values(confirmCriteria).every(Boolean));
    setNewPasswordCriteria(newCriteria);
    setConfirmNewPasswordCriteria(confirmCriteria);
  }, [newPassword, confirmNewPassword]);

  return ReactDOM.createPortal(
    <div className="flex justify-center items-center bg-[#151616] min-h-screen">
      <div className="w-full max-w-md mx-auto bg-[#f8f8ff]/80 dark:bg-[#1f2121]/80 dark:text-white shadow-md rounded-2xl p-6 backdrop-blur-md border border-white/30 dark:border-gray-700/50">
        <img
          // src={LogoImg}
          src="logo-cesarin.png"
          className="max-w-full max-h-40 w-auto h-auto mx-auto dark:filter dark:invert dark:brightness-0"
          alt="Logo"
        />
        <div className="text-center mt-3">
          <p className="text-lg font-medium text-black dark:text-white font-sans">
            {textConfig.LOGIN_TITLE_TEXT_FIRST}
          </p>
        </div>
        <div className="text-center">
          <p className="text-sm font-light text-black dark:text-white font-sans">
            {textConfig.LOGIN_TITLE_TEXT_SECOND}
          </p>
          {errorMessage && (
            <p className="text-sm font-light text-red-500">{errorMessage}</p>
          )}
        </div>
        {needPasswordChange ? (
          <form onSubmit={handleNewPasswordSubmit} className="mt-5">
            <div className="relative">
              <input
                type={showPassword ? "password" : "text"}
                className="border border-white/30 dark:border-gray-700/50 backdrop-blur-md bg-[#ffffff]/30 dark:bg-[#1f2121]/30 focus:ring-2 focus:ring-[#27496D] dark:focus:ring-[#2F5A82] text-black dark:text-white rounded-lg w-full px-4 py-2"
                placeholder={textConfig.LOGIN_PLACEHOLDER_NEW_PASSWORD}
                value={newPassword}
                onChange={handleNewPasswordChange}
                onFocus={handleNewPasswordFocus}
                onBlur={handleNewPasswordBlur}
                required
                ref={newPasswordInputRef}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                aria-label={
                  showPassword ? "Ocultar contraseña" : "Mostrar contraseña"
                }
                ref={toggleButtonRef}
              >
                {showPassword ? (
                  <EyeSlashIcon className="h-5 w-5 text-gray-700" />
                ) : (
                  <EyeIcon className="h-5 w-5 text-gray-700" />
                )}
              </button>
            </div>
          </form>
        ) : (
          <form onSubmit={handleSubmit} className="mt-5">
            <input
              type="text"
              className="border border-white/30 dark:border-gray-700/50 backdrop-blur-md bg-[#ffffff]/30 dark:bg-[#1f2121]/30 focus:ring-2 focus:ring-[#5762F7] dark:focus:ring-[#3e48d0] text-black dark:text-white rounded-lg w-full px-4 py-2"
              placeholder={textConfig.LOGIN_PLACEHOLDER_USERNAME}
              value={user}
              onChange={(e) => setUser(e.target.value)}
              required
            />
            <div className="relative mt-4">
              <input
                type={showPassword ? "text" : "password"}
                className="border border-white/30 dark:border-gray-700/50 backdrop-blur-md bg-[#ffffff]/30 dark:bg-[#1f2121]/30 focus:ring-2 focus:ring-[#5762F7] dark:focus:ring-[#3e48d0] text-black dark:text-white rounded-lg w-full px-4 py-2"
                placeholder={textConfig.LOGIN_PLACEHOLDER_PASSWORD}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                aria-label={
                  showPassword ? "Ocultar contraseña" : "Mostrar contraseña"
                }
                ref={toggleButtonRef}
              >
                {showPassword ? (
                  <EyeSlashIcon className="h-5 w-5 text-gray-700" />
                ) : (
                  <EyeIcon className="h-5 w-5 text-gray-700" />
                )}
              </button>
            </div>
            <button
              type="submit"
              className="bg-[#5762F7] hover:bg-[#3e48d0] text-white w-full py-2 mt-4"
            >
              {textConfig.LOGIN_USER}
            </button>
            {/* Botón para Ingresar AD */}
            <button
              type="button"
              onClick={() => (window.location.href = adLoginUrl)}
              className="bg-[#5762F7] hover:bg-[#3e48d0] text-white w-full py-2 mt-4"
            >
              Ingresar AD
            </button>
            <button
              type="button"
              onClick={handleGuestLogin}
              className="bg-white hover:bg-gray-200 text-black w-full py-2 mt-4"
            >
              {textConfig.LOGIN_GUEST}
            </button>
          </form>
        )}
      </div>
    </div>,
    document.getElementById("modal-root")
  );
}

export default Login;
