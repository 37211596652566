import React, { createContext, useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import { fetchTopics, deleteTopic } from "../services/topicApi";
import { fetchChatHistory, getUserDocuments } from "../services/uploadFilesApi";
import { refreshToken } from "../services/loginApi";
import { jwtDecode } from "jwt-decode";

export const ContextApp = createContext();

const AppContext = ({ children }) => {
  const [showSlide, setShowSlide] = useState(false);
  const [chatValue, setChatValue] = useState("");
  const [message, setMessage] = useState(() => {
    const storedMessages = localStorage.getItem("chatHistory");
    return storedMessages ? JSON.parse(storedMessages) : [];
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [isAwaitingNewResponse, setIsAwaitingNewResponse] = useState(false);
  const ws = useRef(null);
  const [currentTopic, setCurrentTopic] = useState(null);
  const [topics, setTopics] = useState([]);
  const [reloadTopicsTrigger, setReloadTopicsTrigger] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(() => !!Cookies.get("accessToken"));
  const [isGuest, setIsGuest] = useState(() => !Cookies.get("accessToken"));
  const [shouldLoadHistory, setShouldLoadHistory] = useState(false);
  const [expirationDate, setExpirationDate] = useState(() => {
    const expiration = Cookies.get("expirationDate");
    return expiration ? new Date(expiration) : null;
  });
  const [userRole, setUserRole] = useState(null);
  const [username, setUsername] = useState(() => Cookies.get("username"));
  const [configuration, setConfiguration] = useState(null);
  const [collections, setCollections] = useState([]);
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(""); //initial value is empty string
  const [isCollectionSelectorOpen, setIsCollectionSelectorOpen] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const [isFAQSent, setIsFAQSent] = useState(false); // Estado para bloquear el textarea

  
  const fetchMainFAQs = async () => {
    try {
      const response = await fetch("https://ef6epzyw3f.execute-api.us-east-1.amazonaws.com/faqs/getMainQueries"); // Cambiar por tu endpoint
      if (!response.ok) {
        throw new Error("Error al cargar las preguntas frecuentes");
      }
      const data = await response.json();
      console.log(data)
      setFaqList(data.main_queries || []); // Actualizar el estado con las preguntas
    } catch (error) {
      console.error("Error al cargar FAQs:", error);
    }
  };

  useEffect(() => {
    fetchMainFAQs(); // Llamar al backend al montar el componente
  }, []);


  //update selected collection when configuration is loaded
  useEffect(() => {
    if (configuration) {
      const storedCollection = localStorage.getItem("selectedCollection");
      setSelectedCollection(storedCollection || configuration.DEFAULT_COLLECTION);
    }
  }, [configuration, isGuest]);

  const decodeToken = () => {
    const accessToken = Cookies.get("accessToken");
    if (accessToken) {
      const decodedToken = jwtDecode(accessToken);
      setUserRole(
        decodedToken["cognito:groups"].includes("admin") ? "admin" : "user"
      );
      setUsername(decodedToken.username);
    } else {
      setUserRole(null);
      setUsername(null);
    }
  };

  useEffect(() => {
    decodeToken();
    loadCollections();
  }, [isLoggedIn]);

  const awaitingResponseRef = useRef(isAwaitingNewResponse);
  const triggerLoadHistory = (load) => {
    setShouldLoadHistory(load);
  };

  const loadTopics = async () => {
    if (isGuest) {
      setTopics([]);
      return;
    }
    try {
      const fetchedTopics = await fetchTopics();
      setTopics(fetchedTopics);
    } catch (error) {
      console.error("Error al cargar los tópicos:", error);
    }
  };

  const loadCollections = async () => {
    try {
      const documents = await getUserDocuments();
      const uniqueCollections = [...new Set(documents.map(doc => doc.COLLECTION_NAME))];
      setCollections(uniqueCollections);
    } catch (error) {
      console.error("Error al cargar las colecciones:", error);
    }
  };

  const resetWebSocketConnection = () => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.close();
    }
    setupWebSocket();
  };

  useEffect(() => {
    loadCollections();
  }, []);

  useEffect(() => {
    if (isCollectionSelectorOpen) {
      loadCollections();
    }
  }, [isCollectionSelectorOpen]);

  useEffect(() => {
    if (isAwaitingNewResponse) {
    }
  }, [isAwaitingNewResponse]);

  useEffect(() => {
    awaitingResponseRef.current = isAwaitingNewResponse;
  }, [isAwaitingNewResponse]);

  useEffect(() => {
    loadTopics();
    resetChat();
  }, [reloadTopicsTrigger, isLoggedIn, isGuest]);

  useEffect(() => {
    const checkAndRefreshToken = async () => {
      if (!expirationDate) return;
      const now = new Date();
      const timeLeft = expirationDate.getTime() - now.getTime();

      if (timeLeft <= 0) {
        try {
          const data = await refreshToken();
          setIsLoggedIn(true);
          setIsGuest(true);
          setExpirationDate(new Date(Cookies.get("expirationDate")));
        } catch (error) {
          console.error("Error al refrescar el token:", error);
          Cookies.remove("accessToken");
          Cookies.remove("expirationDate");
          setIsLoggedIn(false);
          setIsGuest(true);
        }
      } else {
        const timer = setTimeout(checkAndRefreshToken, timeLeft - 60000);
        return () => clearTimeout(timer);
      }
    };

    checkAndRefreshToken();
  }, [expirationDate]);

  useEffect(() => {
    setIsLoggedIn(!!Cookies.get("accessToken"));
    setIsGuest(!Cookies.get("accessToken"));
  }, [Cookies.get("accessToken")]);

  const deleteTopicFromList = async (topic) => {
    setIsLoadingChat(true);
    try {
      await deleteTopic(topic);
      await loadTopics();
    
      if (currentTopic && currentTopic.id === topic) {
        resetChat(); 
      }
    } catch (error) {
      console.error("Error al eliminar el tópico:", error);
    } finally {
      setIsLoadingChat(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      loadTopics();
    }
  }, [isLoggedIn]);

  async function getWebSocketUrlFromJson() {
    try {
      const response = await fetch('/api.json');
      const data = await response.json();
      return data.API_WEBSOCKET;
    } catch (error) {
      console.error("Error al obtener la URL del WebSocket desde JSON:", error);
      throw error;
    }
  }

  const getWebSocketURL = async () => {
    try {
      const API_WEBSOCKET = await getWebSocketUrlFromJson();
      const WS_URL = API_WEBSOCKET;
      return WS_URL;
    } catch (error) {
      console.error("Error al obtener la URL del WebSocket:", error);
      throw error;
    }
  };

  const setupWebSocket = async () => {
    try {
      const wsPath = await getWebSocketURL();
      if (!ws.current || ws.current.readyState !== WebSocket.OPEN) {
        ws.current = new WebSocket(wsPath);
        ws.current.onopen = () => console.log("Conexión WebSocket abierta");

        ws.current.onmessage = (event) => {
          console.log("Evento WebSocket recibido:", event.data);
        
          let data;
          try {
            // 🔹 Si event.data es un string JSON, parsearlo. Si ya es un objeto, usarlo directamente.
            data = typeof event.data === "string" ? JSON.parse(event.data) : event.data;
          } catch (error) {
            console.error("Error al parsear JSON desde WebSocket:", error);
            return;
          }
        
          console.log("Contenido de data:", data);
        
          // ✅ Si es una respuesta FAQ, manejamos el mensaje y reiniciamos WebSocket
          if (data.FAQ_ANSWER) {
            setMessage((prevMessages) => [
              ...prevMessages,
              {
                text: data.FAQ_ANSWER,
                isBot: true,
                metadata: [], // No mostramos metadata en FAQs
              },
            ]);
        
            // 🔹 Resetear el estado de carga
            setIsAwaitingNewResponse(false);
            setIsWaitingForResponse(false);
            awaitingResponseRef.current = false;
        
            // 🔹 Limpiar cualquier timeout activo
            if (ws.current.timeout) {
              clearTimeout(ws.current.timeout);
            }
        
            // 🔹 Cerrar y reiniciar WebSocket si persiste el problema
            if (ws.current && ws.current.readyState === WebSocket.OPEN) {
              console.log("Cerrando WebSocket para reiniciar...");
              ws.current.close();
            }
        
            setTimeout(() => {
              console.log("Reiniciando WebSocket...");
              resetWebSocketConnection(); // Función que vuelve a abrir el WebSocket
            }, 500);
        
            return; // 🚨 Importante: No continuar con más lógica
          }
        
          // ✅ Si se recibe un TOPIC_ID, actualizar currentTopic
          if (data.TOPIC_ID) {
            setCurrentTopic({
              id: data.TOPIC_ID,
              name: data.TOPIC_NAME,
            });
          } else {
            if (awaitingResponseRef.current) {
              setIsAwaitingNewResponse(false);
              awaitingResponseRef.current = false;
            }
        
            setMessage((prevMessages) => {
              const lastMessage =
                prevMessages.length > 0
                  ? prevMessages[prevMessages.length - 1]
                  : null;
        
              // 🔹 Extraer texto del `token`
              const tokenTexts = Array.isArray(data.token)
                ? data.token.map((item) => item.text).join("")
                : data.token || "";
        
              console.log("Contenido de data:", data);
        
              // 🔹 Restaurar procesamiento de metadata
              let metadata = [];
              if (typeof data === "string") {
                try {
                  console.log("Contenido de data antes del parseo:", data);
                  const parsedData = JSON.parse(data);
                  console.log("Contenido parseado de data:", parsedData);
        
                  if (Array.isArray(parsedData)) {
                    metadata = parsedData;
                  } else if (typeof parsedData === "object") {
                    metadata = [parsedData];
                  }
        
                  console.log("Contenido final de metadata:", metadata);
                } catch (error) {
                  console.error("Error al parsear data como JSON:", error);
                }
              } else if (data.metadata && Array.isArray(data.metadata)) {
                metadata = data.metadata;
              }
        
              if (lastMessage && lastMessage.isBot) {
                return [
                  ...prevMessages.slice(0, prevMessages.length - 1),
                  {
                    ...lastMessage,
                    text: lastMessage.text + tokenTexts,
                    metadata: metadata,
                  },
                ];
              } else {
                return [
                  ...prevMessages,
                  {
                    text: tokenTexts,
                    isBot: true,
                    metadata,
                  },
                ];
              }
            });
          }
        
          // ✅ Limpiar la espera de respuesta después de recibir la respuesta completa
          if (ws.current.timeout) {
            clearTimeout(ws.current.timeout);
          }
        
          ws.current.timeout = setTimeout(() => {
            setIsWaitingForResponse(false);
            awaitingResponseRef.current = false;
            loadTopics();
          }, 1000);
        };
        
        

        ws.current.onerror = (error) =>
          console.error("Error en la conexión WebSocket:", error);
      }
    } catch (error) {
      console.error("Error al configurar el WebSocket:", error);
    }
  };

  const closeWebSocket = () => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.close();
    }
  };

  useEffect(() => {
    setupWebSocket();
    return () => {
      closeWebSocket();
      clearTimeout(ws.current?.timeout);
    };
  }, []);

  useEffect(() => {
    const fetchConfiguration = async () => {
      try {
        const response = await fetch('/configuration.json');
        const config = await response.json();
        setConfiguration(config);
        console.log(`Version: ${config.VERSION}`);

        // Set title
        if (config.TITLE) {
          document.title = config.TITLE;
        }
      } catch (error) {
        console.error("Error al cargar la configuración:", error);
      }
    };

    fetchConfiguration();
  }, []);

  const handleSend = async (inputText = chatValue.trim()) => {
    if (!inputText || !ws.current || ws.current.readyState !== WebSocket.OPEN) {
      console.log("No hay mensaje o el WebSocket no está abierto");
      return;
    }
    if (chatValue.startsWith("FAQ:")) {
      console.log("El mensaje comienza con 'FAQ:'");
      setIsButtonDisabled(false);
      return;
  }
    const guestToken =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJHVUVTVF9VU0VSIiwiY29nbml0bzpncm91cHMiOlsiR1VFU1RfR1JPVVAiXSwiaXNzIjoiZnJvbnQiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJ1c2VybmFtZSI6IkdVRVNUX1VTRVIifQ.PcqpR_wHkLVs1_hnnThLqPfyq9huDLAEPgOojKSVXJo";
  
    const accessToken = Cookies.get("accessToken");
    
    const authHeader = isGuest
      ? `Bearer ${guestToken}`
      : `Bearer ${accessToken}`;

    const messageData = {
      query: inputText,
      collection: selectedCollection || configuration.DEFAULT_COLLECTION,
      Authorization: authHeader,
      topic_id: currentTopic ? currentTopic.id || "" : "", // try to send always as string
    };

    try {
      console.log("Enviando mensaje por WebSocket:", messageData);
      setIsButtonDisabled(true);
      if(messageData.query.startsWith("FAQ:")){setIsButtonDisabled(false)
        
      }      
      ws.current.send(JSON.stringify(messageData));
      setIsWaitingForResponse(true);
      setIsAwaitingNewResponse(true);
      setChatValue("");
      setMessage((prevMessages) => [
        ...prevMessages,
        { text: inputText, isBot: false },
      ]);
    } catch (error) {
      console.error("Error al enviar el mensaje por WebSocket:", error);
    }
  };
  

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        e.preventDefault();
      } else {
        e.preventDefault();
        handleSend();
      }
    }
  };

  const loadChatHistoryForTopic = async (topicId) => {
    setIsLoadingChat(true);
    try {
      closeWebSocket();
      await setupWebSocket();
      const history = await fetchChatHistory(topicId);
      setMessage(
        history.map((msg) => ({
          text: msg.content,
          isBot: msg.type !== "human",
        }))
      );
      setCurrentTopic({ id: topicId });
    } catch (error) {
      console.error("Error al cargar el historial del chat:", error);
    } finally {
      setIsLoadingChat(false);
    }
  };

  const resetChat = (resetFAQ = false) => { // 🔹 Agregar parámetro para resetear el FAQ
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.close();
    }
  
    setCurrentTopic(null);
    setMessage([]);
    setIsButtonDisabled(false)
    setChatValue(""); // 🔹 Limpiar el textarea
    setIsFAQSent(false);
    if (resetFAQ) {
      setIsFAQSent(false); // 🔹 Habilitar el textarea al crear un nuevo chat
    }
  
    setTimeout(() => {
      setupWebSocket();
    }, 300);
  };

  return (
    <ContextApp.Provider
      value={{
        showSlide,
        setMessage,
        setCurrentTopic,
        setChatValue,
        resetChat,
        setIsFAQSent,
        isFAQSent,
        faqList,
        fetchMainFAQs,
        setShowSlide,
        chatValue,
        setChatValue,
        handleSend,
        message,
        handleKeyPress,
        isWaitingForResponse,
        loadChatHistoryForTopic,
        currentTopic,
        topics,
        deleteTopicFromList,
        loadTopics,
        reloadTopicsTrigger,
        setReloadTopicsTrigger,
        resetChat,
        isLoggedIn,
        setIsLoggedIn,
        isGuest,
        setIsGuest,
        isButtonDisabled,
        isAwaitingNewResponse,
        username,
        userRole,
        setUsername,
        collections,
        selectedCollection,
        setSelectedCollection,
        isCollectionSelectorOpen,
        setIsCollectionSelectorOpen,
        loadCollections,
        resetWebSocketConnection,
        isLoadingChat,
        setIsLoadingChat,
      }}
    >
      {children}
    </ContextApp.Provider>
  );
};

export default AppContext;