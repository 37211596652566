import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const GuestChatRedirect = () => {
  const navigate = useNavigate();
  const [adLoginUrl, setAdLoginUrl] = useState("");

  const fetchAdLoginUrl = async () => {
    try {
      const response = await fetch("/api.json");
      const data = await response.json();
      setAdLoginUrl(data.API_ACTIVE_DIRECTORY);
    } catch (error) {
      console.error("Error loading API config:", error);
    }
  };

  useEffect(() => {
    const checkAuthAndRedirect = async () => {
      await fetchAdLoginUrl();

      const accessToken = Cookies.get("accessToken");

      // Si ya hay token, redirige a la ruta protegida (home)
      if (accessToken) {
        navigate("/", { replace: true });
        // Si no hay token, redirige al login externo
      } else {
        if (adLoginUrl) {
          window.location.href = adLoginUrl;
        }
      }
    };

    checkAuthAndRedirect();
  }, [navigate, adLoginUrl]);

  return null;
};

export default GuestChatRedirect;
