import React, { useEffect, useState, useRef, useContext } from "react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { ContextApp } from "../utils/Context";

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn, setIsLoggedIn } = useContext(ContextApp);
  const [isTokenChecked, setIsTokenChecked] = useState(false);
  const [apiTokenUrl, setApiTokenUrl] = useState("");
  const [adLoginUrl, setAdLoginUrl] = useState("");
  const [clientId, setClientId] = useState("");
  const [redirectUri, setRedirectUri] = useState("");
  const hasExchangedCodeRef = useRef(false);

  const fetchApiConfig = async () => {
    try {
      const response = await fetch("/api.json");
      const data = await response.json();
      setApiTokenUrl(data.API_TOKEN_OAUTH2);
      setAdLoginUrl(data.API_ACTIVE_DIRECTORY);
      setClientId(data.API_TOKEN_CLIENT_ID);
      setRedirectUri(data.API_TOKEN_REDIRECT_URI);
      return {
        tokenUrl: data.API_TOKEN_OAUTH2,
        loginUrl: data.API_ACTIVE_DIRECTORY,
        clientId: data.API_TOKEN_CLIENT_ID,
        redirectUri: data.API_TOKEN_REDIRECT_URI,
      };
    } catch (error) {
      console.error("Error loading API config for login URL:", error);
    }
  };

  // Función para intercambiar el code por tokens y extraer el username
  const exchangeCodeForToken = async (
    code,
    tokenUrl,
    clientId,
    redirectUri
  ) => {
    if (hasExchangedCodeRef.current) return;
    hasExchangedCodeRef.current = true;

    // Construimos el cuerpo en formato x-www-form-urlencoded
    const body = new URLSearchParams({
      grant_type: "authorization_code",
      client_id: clientId,
      code,
      redirect_uri: redirectUri,
    });

    try {
      const response = await fetch(tokenUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: body.toString(),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error al intercambiar el code:", errorText);
        throw new Error("Error al obtener el token");
      }

      const data = await response.json();
      Cookies.set("accessToken", data.access_token);
      Cookies.set("refreshToken", data.refresh_token);
      Cookies.set("idToken", data.id_token);
      const decoded = jwtDecode(data.id_token);
      const username = decoded["cognito:username"] || decoded.username;
      Cookies.set("username", username);
      setIsLoggedIn(true);
      window.history.replaceState({}, document.title, window.location.pathname);
    } catch (error) {
      console.error("Error al intercambiar el code:", error);
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    const checkAuthAndRedirect = async () => {
      if (Cookies.get("accessToken")) {
        setIsTokenChecked(true);
        setIsLoggedIn(true);
        return;
      }

      // Cargar la configuración si no está disponible
      const config = await fetchApiConfig();
      const currentClientId = clientId || config?.clientId;
      const currentRedirectUri = redirectUri || config?.redirectUri;
      const currentTokenUrl = apiTokenUrl || config?.tokenUrl;
      const currentLoginUrl = adLoginUrl || config?.loginUrl;

      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      if (code) {
        await exchangeCodeForToken(
          code,
          currentTokenUrl,
          currentClientId,
          currentRedirectUri
        );
        setIsTokenChecked(true);
      } else {
        if (currentLoginUrl) {
          window.location.href = currentLoginUrl;
        }
      }
    };

    checkAuthAndRedirect();
  }, [setIsLoggedIn]);

  if (!isTokenChecked) {
    return null;
  }

  if (!isLoggedIn) {
    return null;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
